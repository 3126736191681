// import React, { useState, useEffect } from 'react';
// import { Sun, Moon, Menu, X } from 'lucide-react';

// const Navbar = () => {
//   const [isDark, setIsDark] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   // Handle scroll effect
//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 20);
//     };
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   // Handle theme toggle
//   const toggleTheme = () => {
//     setIsDark(!isDark);
//     document.documentElement.classList.toggle('dark');
//   };

//   return (
//     <nav className={`fixed w-full z-50 transition-all duration-300 ${
//       isScrolled ? 'bg-white/80 dark:bg-gray-900/80 backdrop-blur-md py-2' : 'bg-transparent py-4'
//     }`}>
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex items-center justify-between">
//           {/* Logo */}
//           <div className="flex-shrink-0">
//             <span className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//               Latentforce.ai
//             </span>
//           </div>

//           {/* Desktop Navigation */}
//           <div className="hidden md:flex items-center space-x-8">
//             <a href="#products" className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//               Products
//             </a>
//             <a href="#agents" className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//               Agents
//             </a>
//             <a href="#team" className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//               Team
//             </a>
//             <a href="#contact" className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//               Contact
//             </a>

//             {/* Theme Toggle */}
//             <button
//               onClick={toggleTheme}
//               className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
//               aria-label="Toggle theme"
//             >
//               {isDark ? (
//                 <Sun className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Moon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>

//             {/* Try Now Button */}
//             <button className="px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg hover:scale-105 transform transition-all duration-200 animate-pulse">
//               Try Now
//             </button>
//           </div>

//           {/* Mobile menu button */}
//           <div className="md:hidden flex items-center space-x-4">
//             <button
//               onClick={toggleTheme}
//               className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
//             >
//               {isDark ? (
//                 <Sun className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Moon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>
//             <button
//               onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
//               className="p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
//             >
//               {isMobileMenuOpen ? (
//                 <X className="w-6 h-6 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Menu className="w-6 h-6 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* Mobile menu */}
//         <div className={`md:hidden transition-all duration-300 ${isMobileMenuOpen ? 'max-h-64 opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`}>
//           <div className="pt-4 pb-3 space-y-3">
//             <a href="#products" className="block text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//               Products
//             </a>
//             <a href="#agents" className="block text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//               Agents
//             </a>
//             <a href="#team" className="block text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//               Team
//             </a>
//             <a href="#contact" className="block text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//               Contact
//             </a>
//             <button className="w-full px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg transform transition-all duration-200 animate-pulse">
//               Try Now
//             </button>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;







// import React, { useState, useEffect } from 'react';
// import { Sun, Moon, Menu, X, ChevronDown } from 'lucide-react';

// const Navbar = () => {
//   const [isDark, setIsDark] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [isDocsOpen, setIsDocsOpen] = useState(false);

//   // Handle scroll effect
//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 20);
//     };
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   // Handle theme toggle
//   const toggleTheme = () => {
//     setIsDark(!isDark);
//     document.documentElement.classList.toggle('dark');
//   };

//   // Smooth scroll to section
//   const scrollToSection = (sectionId) => {
//     const element = document.getElementById(sectionId);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth' });
//       setIsMobileMenuOpen(false);
//     }
//   };

//   return (
//     <nav className={`fixed w-full z-50 transition-all duration-300 ${
//       isScrolled ? 'bg-white/80 dark:bg-gray-900/80 backdrop-blur-md py-2' : 'bg-transparent py-4'
//     }`}>
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex items-center justify-between">
//           {/* Logo */}
//           <div className="flex-shrink-0">
//             <a href="/" className="flex items-center space-x-2">
//               {/* <img src="/images/lf-white.png" alt="Latentforce.ai" className="h-8 w-auto" /> */}
//               <img src="/images/lf-black.png" alt="Latentforce.ai" style={{ height: '50px', width: 'auto' }} />
//               {/* <span className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//                 Latentforce.ai
//               </span> */}
//             </a>
//           </div>

//           {/* Desktop Navigation */}
//           <div className="hidden md:flex items-center space-x-8">
//             <button 
//               onClick={() => scrollToSection('products')}
//               className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//             >
//               Products
//             </button>
//             <button 
//               onClick={() => scrollToSection('agents')}
//               className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//             >
//               Agents
//             </button>
//             <button 
//               onClick={() => scrollToSection('team')}
//               className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//             >
//               Team
//             </button>
//             <button 
//               onClick={() => scrollToSection('contact')}
//               className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//             >
//               Contact
//             </button>

//             {/* Docs Dropdown */}
//             <div className="relative">
//               <button
//                 onClick={() => setIsDocsOpen(!isDocsOpen)}
//                 className="flex items-center space-x-1 text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//               >
//                 <span>Docs</span>
//                 <ChevronDown className="w-4 h-4" />
//               </button>
              
//               {isDocsOpen && (
//                 <div className="absolute top-full mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
//                   <a
//                     href="https://docs.latentforce.ai/AgentForce/getting_started.html"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//                     onClick={() => setIsDocsOpen(false)}
//                   >
//                     AgentForce
//                   </a>
//                 </div>
//               )}
//             </div>

//             {/* Theme Toggle */}
//             <button
//               onClick={toggleTheme}
//               className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
//               aria-label="Toggle theme"
//             >
//               {isDark ? (
//                 <Sun className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Moon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>

//             {/* Try Now Button */}
//             <a
//               href="https://app.latentforce.ai"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="w-full px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg transform transition-all duration-200 animate-pulse"
//             >
//               Try Now
//             </a>
//           </div>

//           {/* Mobile menu button */}
//           <div className="md:hidden flex items-center space-x-4">
//             <button
//               onClick={toggleTheme}
//               className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
//             >
//               {isDark ? (
//                 <Sun className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Moon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>
//             <button
//               onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
//               className="p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
//             >
//               {isMobileMenuOpen ? (
//                 <X className="w-6 h-6 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Menu className="w-6 h-6 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* Mobile menu */}
//         <div className={`md:hidden transition-all duration-300 ${isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`}>
//           <div className="pt-4 pb-3 space-y-3">
//             <button
//               onClick={() => scrollToSection('products')}
//               className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//             >
//               Products
//             </button>
//             <button
//               onClick={() => scrollToSection('agents')}
//               className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//             >
//               Agents
//             </button>
//             <button
//               onClick={() => scrollToSection('team')}
//               className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//             >
//               Team
//             </button>
//             <button
//               onClick={() => scrollToSection('contact')}
//               className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//             >
//               Contact
//             </button>
            
//             {/* Mobile Docs */}
//             <div className="px-4 py-2">
//               <a
//                 href="https://docs.latentforce.ai/AgentForce/getting_started.html"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="block text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400"
//               >
//                 Docs - AgentForce
//               </a>
//             </div>

//             <a
//               href="https://app.latentforce.ai"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="block mx-4 px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white text-center font-semibold hover:shadow-lg transform transition-all duration-200"
//             >
//               Try Now
//             </a>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;























// THIS IS THE FINAL VERSION OF THE WORKING CODE, WITH OPTIMAL CHANGES!
// FIX THIS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


// import React, { useState, useEffect } from 'react';
// import { Sun, Moon, Menu, X, ChevronDown } from 'lucide-react';

// const Navbar = () => {
//   const [isDark, setIsDark] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [isDocsOpen, setIsDocsOpen] = useState(false);

//   // Handle scroll effect
//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 20);
//     };
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   // Handle theme toggle
//   const toggleTheme = () => {
//     setIsDark(!isDark);
//     document.documentElement.classList.toggle('dark');
//   };

//   // Smooth scroll to section
//   const scrollToSection = (sectionId) => {
//     const element = document.getElementById(sectionId);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth' });
//       setIsMobileMenuOpen(false);
//     }
//   };

//   // Close mobile menu when clicking outside
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (isMobileMenuOpen && !event.target.closest('.mobile-menu-container')) {
//         setIsMobileMenuOpen(false);
//       }
//     };

//     document.addEventListener('click', handleClickOutside);
//     return () => document.removeEventListener('click', handleClickOutside);
//   }, [isMobileMenuOpen]);

//   return (
//     <nav className={`fixed w-full z-[1000] transition-all duration-300 ${
//       isScrolled ? 'bg-white/80 dark:bg-gray-900/80 backdrop-blur-md py-2' : 'bg-transparent py-4'
//     }`}>
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex items-center justify-between">
//           {/* Logo */}
//           {/* <div className="flex-shrink-0">
//             <a href="/" className="flex items-center space-x-2"> */}
//               {/* <img src="/images/lf-black.png" alt="Latentforce.ai" style={{ height: '50px', width: 'auto' }} /> */}
//               {/* <img src="/images/lf-white-logo.png" alt="Latentforce.ai" style={{ height: '50px', width: 'auto' }} />
//             </a>
//           </div> */}
//           <div className="flex-shrink-0">
//             <a href="/" className="flex items-center space-x-2">
//               <img
//                 src={isDark ? "/images/lf-white-logo.png" : "/images/lf-black.png"}
//                 alt="Latentforce.ai"
//                 style={{ height: '50px', width: 'auto' }}
//               />
//             </a>
//           </div>

//           {/* Desktop Navigation */}
//           <div className="hidden md:flex items-center space-x-8">
//             <button 
//               onClick={() => scrollToSection('products')}
//               className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//             >
//               Products
//             </button>
//             <button 
//               onClick={() => scrollToSection('agents')}
//               className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//             >
//               Agents
//             </button>
//             <button 
//               onClick={() => scrollToSection('team')}
//               className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//             >
//               Team
//             </button>
//             <button 
//               onClick={() => scrollToSection('contact')}
//               className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//             >
//               Contact
//             </button>

//             {/* Docs Dropdown */}
//             <div className="relative">
//               <button
//                 onClick={() => setIsDocsOpen(!isDocsOpen)}
//                 className="flex items-center space-x-1 text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
//               >
//                 <span>Docs</span>
//                 <ChevronDown className="w-4 h-4" />
//               </button>
              
//               {isDocsOpen && (
//                 <div className="absolute top-full mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
//                   <a
//                     href="https://docs.latentforce.ai/AgentForce/getting_started.html"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//                     onClick={() => setIsDocsOpen(false)}
//                   >
//                     AgentForce
//                   </a>
//                 </div>
//               )}
//             </div>

//             {/* Theme Toggle */}
//             <button
//               onClick={toggleTheme}
//               className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
//               aria-label="Toggle theme"
//             >
//               {isDark ? (
//                 <Sun className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Moon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>

//             {/* Try Now Button */}
//             <a
//               href="https://app.latentforce.ai"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="w-full px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg transform transition-all duration-200 animate-pulse"
//             >
//               Try Now
//             </a>
//           </div>

//           {/* Mobile menu button */}
//           <div className="md:hidden flex items-center space-x-4">
//             <button
//               onClick={toggleTheme}
//               className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
//             >
//               {isDark ? (
//                 <Sun className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Moon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>
//             <button
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setIsMobileMenuOpen(!isMobileMenuOpen);
//               }}
//               className="p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mobile-menu-container"
//             >
//               {isMobileMenuOpen ? (
//                 <X className="w-6 h-6 text-gray-700 dark:text-gray-300" />
//               ) : (
//                 <Menu className="w-6 h-6 text-gray-700 dark:text-gray-300" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* Mobile menu */}
//         <div 
//           className={`md:hidden fixed left-0 right-0 top-[64px] z-[999] transition-all duration-300 ${
//             isMobileMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible pointer-events-none'
//           }`}
//         >
//           <div className="bg-white/95 dark:bg-gray-900/95 backdrop-blur-md shadow-lg">
//             <div className="pt-4 pb-3 space-y-3">
//               <button
//                 onClick={() => scrollToSection('products')}
//                 className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//               >
//                 Products
//               </button>
//               <button
//                 onClick={() => scrollToSection('agents')}
//                 className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//               >
//                 Agents
//               </button>
//               <button
//                 onClick={() => scrollToSection('team')}
//                 className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//               >
//                 Team
//               </button>
//               <button
//                 onClick={() => scrollToSection('contact')}
//                 className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
//               >
//                 Contact
//               </button>
              
//               {/* Mobile Docs */}
//               <div className="px-4 py-2">
//                 <a
//                   href="https://docs.latentforce.ai/AgentForce/getting_started.html"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="block text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400"
//                 >
//                   Docs - AgentForce
//                 </a>
//               </div>

//               <div className="px-4 pb-4">
//                 <a
//                   href="https://app.latentforce.ai"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="block w-full px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white text-center font-semibold hover:shadow-lg transform transition-all duration-200 hover:scale-105"
//                 >
//                   Try Now
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;






import React, { useState, useEffect } from 'react';
import { Sun, Moon, Menu, X, ChevronDown } from 'lucide-react';

const Navbar = () => {
  const [isDark, setIsDark] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDocsOpen, setIsDocsOpen] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle theme toggle
  const toggleTheme = () => {
    setIsDark(!isDark);
    document.documentElement.classList.toggle('dark');
  };

  // Smooth scroll to section
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsMobileMenuOpen(false);
    }
  };

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileMenuOpen && !event.target.closest('.mobile-menu-container')) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isMobileMenuOpen]);

  return (
    <nav className={`fixed w-full z-[1000] transition-all duration-300 ${
      isScrolled ? 'bg-white/80 dark:bg-gray-900/80 backdrop-blur-md py-2' : 'bg-transparent py-4'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex-shrink-0">
            <a href="/" className="flex items-center space-x-2">
              <img
                src={isDark ? "/images/lf-white-logo.png" : "/images/lf-black.png"}
                alt="Latentforce.ai"
                style={{ height: '50px', width: 'auto' }}
              />
            </a>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <button 
              onClick={() => scrollToSection('products')}
              className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
            >
              Products
            </button>
            <button 
              onClick={() => scrollToSection('agents')}
              className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
            >
              Agents
            </button>
            <button 
              onClick={() => scrollToSection('team')}
              className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
            >
              Team
            </button>
            <button 
              onClick={() => scrollToSection('contact')}
              className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
            >
              Contact
            </button>
            <a 
              href="https://latentforce.ai/careers"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
            >
              Careers
            </a>

            {/* Docs Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsDocsOpen(!isDocsOpen)}
                className="flex items-center space-x-1 text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
              >
                <span>Docs</span>
                <ChevronDown className="w-4 h-4" />
              </button>
              
              {isDocsOpen && (
                <div className="absolute top-full mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
                  <a
                    href="https://docs.latentforce.ai/AgentForce/getting_started.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => setIsDocsOpen(false)}
                  >
                    AgentForce
                  </a>
                  <a
                    href="https://docs.extractors.ai/getting_started/installation.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => setIsDocsOpen(false)}
                  >
                    Extractors
                  </a>
                </div>
              )}
            </div>

            {/* Theme Toggle */}
            <button
              onClick={toggleTheme}
              className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
              aria-label="Toggle theme"
            >
              {isDark ? (
                <Sun className="w-5 h-5 text-gray-700 dark:text-gray-300" />
              ) : (
                <Moon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
              )}
            </button>

            {/* Try Now Button */}
            <a
              href="https://app.latentforce.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg transform transition-all duration-200 animate-pulse"
            >
              Try Now
            </a>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center space-x-4">
            <button
              onClick={toggleTheme}
              className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
            >
              {isDark ? (
                <Sun className="w-5 h-5 text-gray-700 dark:text-gray-300" />
              ) : (
                <Moon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
              )}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
              className="p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors mobile-menu-container"
            >
              {isMobileMenuOpen ? (
                <X className="w-6 h-6 text-gray-700 dark:text-gray-300" />
              ) : (
                <Menu className="w-6 h-6 text-gray-700 dark:text-gray-300" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div 
          className={`md:hidden fixed left-0 right-0 top-[64px] z-[999] transition-all duration-300 ${
            isMobileMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible pointer-events-none'
          }`}
        >
          <div className="bg-white/95 dark:bg-gray-900/95 backdrop-blur-md shadow-lg">
            <div className="pt-4 pb-3 space-y-3">
              <button
                onClick={() => scrollToSection('products')}
                className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Products
              </button>
              <button
                onClick={() => scrollToSection('agents')}
                className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Agents
              </button>
              <button
                onClick={() => scrollToSection('team')}
                className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Team
              </button>
              <button
                onClick={() => scrollToSection('contact')}
                className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Contact
              </button>
              <a
                href="https://latentforce.ai/careers"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Careers
              </a>
              
              {/* Mobile Docs */}
              <div className="px-4 py-2 space-y-2">
                <a
                  href="https://docs.latentforce.ai/AgentForce/getting_started.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400"
                >
                  Docs - AgentForce
                </a>
                <a
                  href="https://docs.extractors.ai/getting_started/installation.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-gray-700 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400"
                >
                  Docs - Extractors
                </a>
              </div>

              <div className="px-4 pb-4">
                <a
                  href="https://app.latentforce.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block w-full px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white text-center font-semibold hover:shadow-lg transform transition-all duration-200 hover:scale-105"
                >
                  Try Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;































// NOT SO GOOD



// import React, { useState, useEffect } from 'react';
// import { Sun, Moon } from 'lucide-react';

// const Navbar = () => {
//   const [isDark, setIsDark] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [activeSection, setActiveSection] = useState('home');

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 20);
      
//       // Update active section based on scroll position
//       const sections = ['home', 'products', 'agents', 'team'];
//       const current = sections.find(section => {
//         const element = document.getElementById(section);
//         if (element) {
//           const rect = element.getBoundingClientRect();
//           return rect.top >= 0 && rect.top <= window.innerHeight / 2;
//         }
//         return false;
//       });
//       if (current) setActiveSection(current);
//     };
    
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   const toggleTheme = () => {
//     setIsDark(!isDark);
//     document.documentElement.classList.toggle('dark');
//   };

//   const navItems = [
//     { id: 'products', label: 'Products' },
//     { id: 'agents', label: 'Agents' },
//     { id: 'team', label: 'Team' }
//   ];

//   return (
//     <nav 
//       className={`fixed w-full z-50 transition-all duration-500 ${
//         isScrolled 
//           ? 'bg-white/70 dark:bg-black/70 backdrop-blur-lg py-4 shadow-lg' 
//           : 'bg-transparent py-6'
//       }`}
//     >
//       <div className="max-w-7xl mx-auto px-6 lg:px-8">
//         <div className="flex items-center justify-between">
//           {/* Logo */}
//           <div className="relative group">
//             <span className="text-2xl font-light tracking-tight">
//               latent<span className="font-bold">force</span>
//               <span className="text-purple-600">.ai</span>
//             </span>
//             <div className="absolute -bottom-1 left-0 w-0 h-0.5 bg-purple-600 group-hover:w-full transition-all duration-300" />
//           </div>

//           {/* Desktop Navigation */}
//           <div className="hidden md:flex items-center space-x-12">
//             {navItems.map(({ id, label }) => (
//               <a
//                 key={id}
//                 href={`#${id}`}
//                 className={`relative group ${
//                   activeSection === id 
//                     ? 'text-purple-600 dark:text-purple-400' 
//                     : 'text-gray-600 dark:text-gray-300'
//                 }`}
//               >
//                 <span className="relative z-10">{label}</span>
//                 <div className={`absolute -bottom-1 left-0 h-0.5 bg-purple-600 transition-all duration-300 ${
//                   activeSection === id ? 'w-full' : 'w-0 group-hover:w-full'
//                 }`} />
//               </a>
//             ))}

//             {/* Theme Toggle */}
//             <button
//               onClick={toggleTheme}
//               className="relative w-10 h-10 rounded-full overflow-hidden group"
//               aria-label="Toggle theme"
//             >
//               <div className="absolute inset-0 flex items-center justify-center transition-transform duration-500 bg-transparent group-hover:scale-110">
//                 {isDark ? (
//                   <Sun className="w-5 h-5 text-yellow-400" />
//                 ) : (
//                   <Moon className="w-5 h-5 text-gray-600" />
//                 )}
//               </div>
//               <div className="absolute inset-0 bg-gray-100 dark:bg-gray-800 rounded-full transform scale-0 group-hover:scale-100 transition-transform duration-300" />
//             </button>

//             {/* Try Now Button */}
//             <button className="relative overflow-hidden px-8 py-2 rounded-full bg-black dark:bg-white text-white dark:text-black font-medium group">
//               <span className="relative z-10">Try Now</span>
//               <div className="absolute inset-0 bg-purple-600 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300" />
//             </button>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar   ;











