import React, { useEffect } from 'react';

const LatentForceChat = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = 'https://app.latentforce.ai/api/cleo/cleo-widgets.js';
    script.async = true;
    
    // Initialize the chat widget after script loads
    script.onload = () => {
      if (window.SecureLatentForceChat) {
        new window.SecureLatentForceChat({
          widgetId: '70ed0dcf',  // Your widget ID
          userType: 'default',
        });
      }
    };
    
    // Add script to document
    document.body.appendChild(script);

    // Cleanup on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this runs once on mount

  return <div id="cleo-chat-container" />; // Container for the chat widget
};

export default LatentForceChat;