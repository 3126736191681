// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { MessageSquare, Brain, ArrowRight, Cpu } from 'lucide-react';

// const ProductsSection = () => {
//   const [activeProduct, setActiveProduct] = useState('cleo');

//   const products = {
//     cleo: {
//       title: "Cleo",
//       subtitle: "The Perfect AI Agent for Your Software Product",
//       icon: <MessageSquare className="w-12 h-12" />,
//       color: "blue",
//       features: [
//         "Seamless Integration with Your Data",
//         "Custom Chat Interface",
//         "Document & API Integration",
//         "Enhanced Customer Experience"
//       ],
//       bgGradient: "from-purple-500/10 to-purple-500/5"
//     },
//     modelforce: {
//       title: "ModelForce",
//       subtitle: "Build Your Models and Own Them Too",
//       icon: <Brain className="w-12 h-12" />,
//       color: "blue",
//       features: [
//         "Custom LLM Training",
//         "Business-Specific Models",
//         "Complete Ownership",
//         "Expert Guidance"
//       ],
//       bgGradient: "from-blue-500/10 to-blue-500/5"
//     },
//     agentslab: {
//       title: "AgentsLab",
//       subtitle: "Automate Your Business Processes",
//       icon: <Cpu className="w-8 h-8" />,
//       color: "blue",
//       features: [
//         "Advanced LLM Agents",
//         "Workflow Automation",
//         "Secure On-Premise Options",
//         "Seamless System Integration"
//       ],
//       bgGradient: "from-pink-500/10 to-pink-500/5"
//     }
//   };

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: { staggerChildren: 0.2 }
//     }
//   };

//   const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: { duration: 0.5 }
//     }
//   };

//   return (
//     <section className="py-20 px-4 bg-white dark:bg-gray-900">
//       <div className="group p-6 rounded-2xl bg-white/40 dark:bg-gray-800/40 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300 border border-white/20 dark:border-gray-700/20">
//         <motion.div
//           initial="hidden"
//           whileInView="visible"
//           viewport={{ once: true }}
//           variants={containerVariants}
//         >
//           {/* Section Header */}
//           <motion.div variants={itemVariants} className="text-center mb-16">
//           <h2 className="text-4xl md:text-5xl font-bold mb-6">
//             <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//               Our Products
//             </span>
//           </h2>
//             <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
//               Transform your business with our suite of AI-powered solutions
//             </p>
//           </motion.div>

//           {/* Product Navigation */}
//           <motion.div 
//             variants={itemVariants}
//             className="flex flex-wrap justify-center gap-4 mb-12"
//           >
//             {Object.entries(products).map(([key, product]) => (
//               <button
//                 key={key}
//                 onClick={() => setActiveProduct(key)}
//                 className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 ${
//                   activeProduct === key
//                     ? `bg-${product.color}-500 text-white shadow-lg scale-105`
//                     : `bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-${product.color}-100 dark:hover:bg-${product.color}-900/30`
//                 }`}
//               >
//                 {product.title}
//               </button>
//             ))}
//           </motion.div>

//           {/* Product Display */}
//           <motion.div
//             layout
//             className={`rounded-3xl p-8 md:p-12 bg-gradient-to-br ${products[activeProduct].bgGradient}`}
//           >
//             <div className="grid md:grid-cols-2 gap-8 items-center">
//               {/* Product Info */}
//               <motion.div
//                 initial={{ opacity: 0, x: -20 }}
//                 animate={{ opacity: 1, x: 0 }}
//                 transition={{ duration: 0.5 }}
//               >
//                 <div className={`inline-block p-3 rounded-2xl bg-${products[activeProduct].color}-500/10 mb-6`}>
//                   {products[activeProduct].icon}
//                 </div>
//                 <h3 className="text-3xl font-bold mb-4">{products[activeProduct].title}</h3>
//                 <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
//                   {products[activeProduct].subtitle}
//                 </p>
//                 <ul className="space-y-4">
//                   {products[activeProduct].features.map((feature, index) => (
//                     <motion.li
//                       key={index}
//                       initial={{ opacity: 0, x: -20 }}
//                       animate={{ opacity: 1, x: 0 }}
//                       transition={{ delay: index * 0.1 }}
//                       className="flex items-center text-gray-700 dark:text-gray-200"
//                     >
//                       <ArrowRight className="w-5 h-5 mr-3 text-purple-500" />
//                       {feature}
//                     </motion.li>
//                   ))}
//                 </ul>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   className={`mt-8 px-8 py-3 rounded-full bg-${products[activeProduct].color}-500 text-white font-semibold hover:shadow-lg transition-all duration-300`}
//                 >
//                   Learn More
//                 </motion.button>
//               </motion.div>

//               {/* Product Visual */}
//               <motion.div
//                 initial={{ opacity: 0, scale: 0.8 }}
//                 animate={{ opacity: 1, scale: 1 }}
//                 transition={{ duration: 0.5 }}
//                 className="relative aspect-square"
//               >
//                 <div className="absolute inset-0 flex items-center justify-center">
//                   <div className={`w-64 h-64 rounded-full bg-${products[activeProduct].color}-500/20 animate-pulse`} />
//                   <div className={`absolute w-48 h-48 rounded-full bg-${products[activeProduct].color}-500/30 animate-pulse animation-delay-1000`} />
//                   <div className={`absolute w-32 h-32 rounded-full bg-${products[activeProduct].color}-500/40 animate-pulse animation-delay-2000`} />
//                 </div>
//               </motion.div>
//             </div>
//           </motion.div>
//         </motion.div>
//       </div>
//     </section>
//   );
// };

// export default ProductsSection;










// Final Version 

// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { MessageSquare, Brain, ArrowRight, Cpu } from 'lucide-react';

// const ProductsSection = () => {
//   const [activeProduct, setActiveProduct] = useState('cleo');

//   const products = {
//     cleo: {
//       title: "Cleo",
//       subtitle: "The Perfect AI Agent for Your Software Product",
//       icon: <MessageSquare className="w-12 h-12 text-purple-600 dark:text-purple-400" />,
//       color: "blue",
//       features: [
//         "Seamless Integration with Your Data",
//         "Custom Chat Interface",
//         "Document & API Integration",
//         "Enhanced Customer Experience"
//       ],
//       bgGradient: "from-purple-500/10 to-purple-500/5",
//       darkBgGradient: "dark:from-purple-500/20 dark:to-purple-500/10"
//     },
//     modelforce: {
//       title: "ModelForce",
//       subtitle: "Build Your Models and Own Them Too",
//       icon: <Brain className="w-12 h-12 text-blue-600 dark:text-blue-400" />,
//       color: "blue",
//       features: [
//         "Custom LLM Training",
//         "Business-Specific Models",
//         "Complete Ownership",
//         "Expert Guidance"
//       ],
//       bgGradient: "from-blue-500/10 to-blue-500/5",
//       darkBgGradient: "dark:from-blue-500/20 dark:to-blue-500/10"
//     },
//     agentslab: {
//       title: "AgentsLab",
//       subtitle: "Automate Your Business Processes",
//       icon: <Cpu className="w-8 h-8 text-pink-600 dark:text-pink-400" />,
//       color: "blue",
//       features: [
//         "Advanced LLM Agents",
//         "Workflow Automation",
//         "Secure On-Premise Options",
//         "Seamless System Integration"
//       ],
//       bgGradient: "from-pink-500/10 to-pink-500/5",
//       darkBgGradient: "dark:from-pink-500/20 dark:to-pink-500/10"
//     }
//   };

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: { staggerChildren: 0.2 }
//     }
//   };

//   const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: { duration: 0.5 }
//     }
//   };

//   return (
//     <section className="py-20 px-4 bg-white dark:bg-gray-900" id="products">
//       <div className="group p-6 rounded-2xl bg-white/40 dark:bg-gray-800/40 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300 border border-white/20 dark:border-gray-700/20">
//         <motion.div
//           initial="hidden"
//           whileInView="visible"
//           viewport={{ once: true }}
//           variants={containerVariants}
//         >
//           {/* Section Header */}
//           <motion.div variants={itemVariants} className="text-center mb-16">
//             <h2 className="text-4xl md:text-5xl font-bold mb-6">
//               <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//                 Our Products
//               </span>
//             </h2>
//             <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
//               Transform your business with our suite of AI-powered solutions
//             </p>
//           </motion.div>

//           {/* Product Navigation */}
//           <motion.div 
//             variants={itemVariants}
//             className="flex flex-wrap justify-center gap-4 mb-12"
//           >
//             {Object.entries(products).map(([key, product]) => (
//               <button
//                 key={key}
//                 onClick={() => setActiveProduct(key)}
//                 className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 ${
//                   activeProduct === key
//                     ? `bg-${product.color}-500 text-white shadow-lg scale-105`
//                     : `bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-${product.color}-100 dark:hover:bg-${product.color}-900/30`
//                 }`}
//               >
//                 {product.title}
//               </button>
//             ))}
//           </motion.div>

//           {/* Product Display */}
//           <motion.div
//             layout
//             className={`rounded-3xl p-8 md:p-12 bg-gradient-to-br ${products[activeProduct].bgGradient} ${products[activeProduct].darkBgGradient} backdrop-blur-sm`}
//           >
//             <div className="grid md:grid-cols-2 gap-8 items-center">
//               {/* Product Info */}
//               <motion.div
//                 initial={{ opacity: 0, x: -20 }}
//                 animate={{ opacity: 1, x: 0 }}
//                 transition={{ duration: 0.5 }}
//                 className="relative z-10"
//               >
//                 <div className={`inline-block p-3 rounded-2xl bg-${products[activeProduct].color}-500/10 dark:bg-${products[activeProduct].color}-500/20 backdrop-blur-lg mb-6 ring-1 ring-white/10 dark:ring-white/20`}>
//                   {products[activeProduct].icon}
//                 </div>
//                 <h3 className="text-3xl font-bold mb-4 text-gray-800 dark:text-white">
//                   {products[activeProduct].title}
//                 </h3>
//                 <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
//                   {products[activeProduct].subtitle}
//                 </p>
//                 <ul className="space-y-4">
//                   {products[activeProduct].features.map((feature, index) => (
//                     <motion.li
//                       key={index}
//                       initial={{ opacity: 0, x: -20 }}
//                       animate={{ opacity: 1, x: 0 }}
//                       transition={{ delay: index * 0.1 }}
//                       className="flex items-center text-gray-700 dark:text-gray-200"
//                     >
//                       <ArrowRight className="w-5 h-5 mr-3 text-purple-500 dark:text-purple-400" />
//                       {feature}
//                     </motion.li>
//                   ))}
//                 </ul>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   className={`mt-8 px-8 py-3 rounded-full bg-${products[activeProduct].color}-500 text-white font-semibold hover:shadow-lg transition-all duration-300 shadow-${products[activeProduct].color}-500/20 dark:shadow-${products[activeProduct].color}-500/40`}
//                 >
//                   Learn More
//                 </motion.button>
//               </motion.div>

//               {/* Product Visual */}
//               <motion.div
//                 initial={{ opacity: 0, scale: 0.8 }}
//                 animate={{ opacity: 1, scale: 1 }}
//                 transition={{ duration: 0.5 }}
//                 className="relative aspect-square"
//               >
//                 <div className="absolute inset-0 flex items-center justify-center">
//                   <div className={`w-64 h-64 rounded-full bg-${products[activeProduct].color}-500/20 dark:bg-${products[activeProduct].color}-500/30 animate-pulse blur-lg`} />
//                   <div className={`absolute w-48 h-48 rounded-full bg-${products[activeProduct].color}-500/30 dark:bg-${products[activeProduct].color}-500/40 animate-pulse animation-delay-1000 blur-lg`} />
//                   <div className={`absolute w-32 h-32 rounded-full bg-${products[activeProduct].color}-500/40 dark:bg-${products[activeProduct].color}-500/50 animate-pulse animation-delay-2000 blur-lg`} />
//                 </div>
//               </motion.div>
//             </div>
//           </motion.div>
//         </motion.div>
//       </div>
//     </section>
//   );
// };

// export default ProductsSection;










// FINAL VERSION WITH VIDEO and IMAGES FOR THE PRODUCTS - V1


// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { MessageSquare, Brain, ArrowRight, Cpu } from 'lucide-react';

// const ProductsSection = () => {
//   const [activeProduct, setActiveProduct] = useState('cleo');

//   const products = {
//     cleo: {
//       title: "Cleo",
//       subtitle: "The Perfect AI Agent for Your Software Product",
//       icon: <MessageSquare className="w-12 h-12 text-purple-600 dark:text-purple-400" />,
//       color: "blue",
//       features: [
//         "Seamless Integration with Your Data",
//         "Custom Chat Interface",
//         "Document & API Integration",
//         "Enhanced Customer Experience"
//       ],
//       bgGradient: "from-purple-500/10 to-purple-500/5",
//       darkBgGradient: "dark:from-purple-500/20 dark:to-purple-500/10",
//       videoUrl: "/videos/cleo-video.mp4" // Add the URL for the video
//     },
//     modelforce: {
//       title: "ModelForce",
//       subtitle: "Build Your Models and Own Them Too",
//       icon: <Brain className="w-12 h-12 text-blue-600 dark:text-blue-400" />,
//       color: "blue",
//       features: [
//         "Custom LLM Training",
//         "Business-Specific Models",
//         "Complete Ownership",
//         "Expert Guidance"
//       ],
//       bgGradient: "from-blue-500/10 to-blue-500/5",
//       darkBgGradient: "dark:from-blue-500/20 dark:to-blue-500/10"
//     },
//     agentslab: {
//       title: "AgentsLab",
//       subtitle: "Automate Your Business Processes",
//       icon: <Cpu className="w-8 h-8 text-pink-600 dark:text-pink-400" />,
//       color: "blue",
//       features: [
//         "Advanced LLM Agents",
//         "Workflow Automation",
//         "Secure On-Premise Options",
//         "Seamless System Integration"
//       ],
//       bgGradient: "from-pink-500/10 to-pink-500/5",
//       darkBgGradient: "dark:from-pink-500/20 dark:to-pink-500/10"
//     }
//   };

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: { staggerChildren: 0.2 }
//     }
//   };

//   const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: { duration: 0.5 }
//     }
//   };

//   return (
//     <section className="py-20 px-4 bg-white dark:bg-gray-900" id="products">
//       <div className="group p-6 rounded-2xl bg-white/40 dark:bg-gray-800/40 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300 border border-white/20 dark:border-gray-700/20">
//         <motion.div
//           initial="hidden"
//           whileInView="visible"
//           viewport={{ once: true }}
//           variants={containerVariants}
//         >
//           <motion.div variants={itemVariants} className="text-center mb-16">
//             <h2 className="text-4xl md:text-5xl font-bold mb-6">
//               <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//                 Our Products
//               </span>
//             </h2>
//             <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
//               Transform your business with our suite of AI-powered solutions
//             </p>
//           </motion.div>

//           <motion.div 
//             variants={itemVariants}
//             className="flex flex-wrap justify-center gap-4 mb-12"
//           >
//             {Object.entries(products).map(([key, product]) => (
//               <button
//                 key={key}
//                 onClick={() => setActiveProduct(key)}
//                 className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 ${
//                   activeProduct === key
//                     ? `bg-${product.color}-500 text-white shadow-lg scale-105`
//                     : `bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-${product.color}-100 dark:hover:bg-${product.color}-900/30`
//                 }`}
//               >
//                 {product.title}
//               </button>
//             ))}
//           </motion.div>

//           <motion.div
//             layout
//             className={`rounded-3xl p-8 md:p-12 bg-gradient-to-br ${products[activeProduct].bgGradient} ${products[activeProduct].darkBgGradient} backdrop-blur-sm`}
//           >
//             <div className="grid md:grid-cols-2 gap-8 items-center">
//               <motion.div
//                 initial={{ opacity: 0, x: -20 }}
//                 animate={{ opacity: 1, x: 0 }}
//                 transition={{ duration: 0.5 }}
//                 className="relative z-10"
//               >
//                 <div className={`inline-block p-3 rounded-2xl bg-${products[activeProduct].color}-500/10 dark:bg-${products[activeProduct].color}-500/20 backdrop-blur-lg mb-6 ring-1 ring-white/10 dark:ring-white/20`}>
//                   {products[activeProduct].icon}
//                 </div>
//                 <h3 className="text-3xl font-bold mb-4 text-gray-800 dark:text-white">
//                   {products[activeProduct].title}
//                 </h3>
//                 <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
//                   {products[activeProduct].subtitle}
//                 </p>
//                 <ul className="space-y-4">
//                   {products[activeProduct].features.map((feature, index) => (
//                     <motion.li
//                       key={index}
//                       initial={{ opacity: 0, x: -20 }}
//                       animate={{ opacity: 1, x: 0 }}
//                       transition={{ delay: index * 0.1 }}
//                       className="flex items-center text-gray-700 dark:text-gray-200"
//                     >
//                       <ArrowRight className="w-5 h-5 mr-3 text-purple-500 dark:text-purple-400" />
//                       {feature}
//                     </motion.li>
//                   ))}
//                 </ul>
//               </motion.div>

//               <motion.div
//                 initial={{ opacity: 0, scale: 0.8 }}
//                 animate={{ opacity: 1, scale: 1 }}
//                 transition={{ duration: 0.5 }}
//                 className="relative aspect-square"
//               >
//                 {/* {activeProduct === 'cleo' && products.cleo.videoUrl ? (
//                   <video
//                     className="w-full h-full rounded-lg shadow-lg"
//                     controls
//                   >
//                     <source src={products.cleo.videoUrl} type="video/mp4" />
//                     Your browser does not support the video tag.
//                   </video>
//                 ) : (
//                   <div className="absolute inset-0 flex items-center justify-center">
//                     <div className={`w-64 h-64 rounded-full bg-${products[activeProduct].color}-500/20 dark:bg-${products[activeProduct].color}-500/30 animate-pulse blur-lg`} />
//                   </div>
//                 )} */}


// {activeProduct === 'cleo' && products.cleo.videoUrl ? (
//   <div className="flex justify-center items-center mt-20">
//     <div className="w-full max-w-md aspect-w-16 aspect-h-9">
//       <video className="w-full h-full rounded-lg shadow-lg" controls>
//         <source src={products.cleo.videoUrl} type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>
//     </div>
//   </div>
// ) : activeProduct === 'modelForce' && products.modelForce.imageUrl ? (
//   <div className="flex justify-center items-center mt-20 space-x-10">
//     <div className="w-full max-w-md aspect-w-16 aspect-h-9">
//       <img
//         src={products.modelForce.imageUrl}
//         alt="Model Force"
//         className="w-full h-auto rounded-lg shadow-lg"
//       />
//     </div>
//   </div>
// ) : activeProduct === 'agentsLab' && products.agentsLab.imageUrl ? (
//   <div className="flex justify-center items-center mt-20 space-x-10">
//     <div className="w-full max-w-md aspect-w-16 aspect-h-9">
//       <img
//         src={products.agentsLab.imageUrl}
//         alt="Agents Lab"
//         className="w-full h-auto rounded-lg shadow-lg"
//       />
//     </div>
//   </div>
// ) : (
//   <div className="absolute inset-0 flex items-center justify-center">
//     <div
//       className={`w-64 h-64 rounded-full bg-${products[activeProduct].color}-500/20 dark:bg-${products[activeProduct].color}-500/30 animate-pulse blur-lg`}
//     />
//   </div>
// )}

//               </motion.div>
//             </div>
//           </motion.div>
//         </motion.div>
//       </div>
//     </section>
//   );
// };

// export default ProductsSection;











// This is version 2, where extractors was added


import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, Brain, ArrowRight, Cpu, FileText } from 'lucide-react';

const ProductsSection = () => {
  const [activeProduct, setActiveProduct] = useState('cleo');

  const products = {
    cleo: {
      title: "Cleo",
      subtitle: "The Perfect AI Agent for Your Software Product",
      icon: <MessageSquare className="w-12 h-12 text-purple-600 dark:text-purple-400" />,
      color: "blue",
      features: [
        "Seamless Integration with Your Data",
        "Custom Chat Interface",
        "Document & API Integration",
        "Enhanced Customer Experience"
      ],
      bgGradient: "from-purple-500/10 to-purple-500/5",
      darkBgGradient: "dark:from-purple-500/20 dark:to-purple-500/10",
      videoUrl: "/videos/cleo-video.mp4"
    },
    modelforce: {
      title: "ModelForce",
      subtitle: "Build Your Models and Own Them Too",
      icon: <Brain className="w-12 h-12 text-blue-600 dark:text-blue-400" />,
      color: "blue",
      features: [
        "Custom LLM Training",
        "Business-Specific Models",
        "Complete Ownership",
        "Expert Guidance"
      ],
      bgGradient: "from-blue-500/10 to-blue-500/5",
      darkBgGradient: "dark:from-blue-500/20 dark:to-blue-500/10"
    },
    agentslab: {
      title: "AgentsLab",
      subtitle: "Automate Your Business Processes",
      icon: <Cpu className="w-8 h-8 text-pink-600 dark:text-pink-400" />,
      color: "blue",
      features: [
        "Advanced LLM Agents",
        "Workflow Automation",
        "Secure On-Premise Options",
        "Seamless System Integration"
      ],
      bgGradient: "from-pink-500/10 to-pink-500/5",
      darkBgGradient: "dark:from-pink-500/20 dark:to-pink-500/10"
    },
    extractors: {
      title: "Extractors",
      subtitle: "AI-Powered Document Extraction",
      icon: <FileText className="w-12 h-12 text-emerald-600 dark:text-emerald-400" />,
      color: "blue",
      features: [
        "PDF to Excel with Structure Preservation",
        "Image to Excel via Advanced OCR",
        "PDF to Clean Markdown Conversion",
        "Multi-Language Document Processing"
      ],
      bgGradient: "from-emerald-500/10 to-emerald-500/5",
      darkBgGradient: "dark:from-emerald-500/20 dark:to-emerald-500/10"
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <section className="py-20 px-4 bg-white dark:bg-gray-900" id="products">
      <div className="group p-6 rounded-2xl bg-white/40 dark:bg-gray-800/40 backdrop-blur-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300 border border-white/20 dark:border-gray-700/20">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={containerVariants}
        >
          <motion.div variants={itemVariants} className="text-center mb-16">
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
                Our Products
              </span>
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Transform your business with our suite of AI-powered solutions
            </p>
          </motion.div>

          <motion.div 
            variants={itemVariants}
            className="flex flex-wrap justify-center gap-4 mb-12"
          >
            {Object.entries(products).map(([key, product]) => (
              <button
                key={key}
                onClick={() => setActiveProduct(key)}
                className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 ${
                  activeProduct === key
                    ? `bg-${product.color}-500 text-white shadow-lg scale-105`
                    : `bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-${product.color}-100 dark:hover:bg-${product.color}-900/30`
                }`}
              >
                {product.title}
              </button>
            ))}
          </motion.div>

          <motion.div
            layout
            className={`rounded-3xl p-8 md:p-12 bg-gradient-to-br ${products[activeProduct].bgGradient} ${products[activeProduct].darkBgGradient} backdrop-blur-sm`}
          >
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="relative z-10"
              >
                <div className={`inline-block p-3 rounded-2xl bg-${products[activeProduct].color}-500/10 dark:bg-${products[activeProduct].color}-500/20 backdrop-blur-lg mb-6 ring-1 ring-white/10 dark:ring-white/20`}>
                  {products[activeProduct].icon}
                </div>
                <h3 className="text-3xl font-bold mb-4 text-gray-800 dark:text-white">
                  {products[activeProduct].title}
                </h3>
                <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
                  {products[activeProduct].subtitle}
                </p>
                <ul className="space-y-4">
                  {products[activeProduct].features.map((feature, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-center text-gray-700 dark:text-gray-200"
                    >
                      <ArrowRight className="w-5 h-5 mr-3 text-purple-500 dark:text-purple-400" />
                      {feature}
                    </motion.li>
                  ))}
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="relative aspect-square"
              >
                {activeProduct === 'cleo' && products.cleo.videoUrl ? (
                  <div className="flex justify-center items-center mt-20">
                    <div className="w-full max-w-md aspect-w-16 aspect-h-9">
                      <video className="w-full h-full rounded-lg shadow-lg" controls>
                        <source src={products.cleo.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                ) : activeProduct === 'modelforce' && products.modelforce.imageUrl ? (
                  <div className="flex justify-center items-center mt-20 space-x-10">
                    <div className="w-full max-w-md aspect-w-16 aspect-h-9">
                      <img
                        src={products.modelforce.imageUrl}
                        alt="Model Force"
                        className="w-full h-auto rounded-lg shadow-lg"
                      />
                    </div>
                  </div>
                ) : activeProduct === 'agentslab' && products.agentslab.imageUrl ? (
                  <div className="flex justify-center items-center mt-20 space-x-10">
                    <div className="w-full max-w-md aspect-w-16 aspect-h-9">
                      <img
                        src={products.agentslab.imageUrl}
                        alt="Agents Lab"
                        className="w-full h-auto rounded-lg shadow-lg"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div
                      className={`w-64 h-64 rounded-full bg-${products[activeProduct].color}-500/20 dark:bg-${products[activeProduct].color}-500/30 animate-pulse blur-lg`}
                    />
                  </div>
                )}
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default ProductsSection;