import React, { useState } from 'react';
import { motion } from 'framer-motion';
// import { FileText, Search, Id, Database, Code, Zap } from 'lucide-react';

import { FileText, Search, Database, Code, Zap, BadgeCheck, Cpu } from 'lucide-react';


const AgentsSection = () => {
  const [hoveredAgent, setHoveredAgent] = useState(null);

  const agents = [
    {
      id: 'pdf',
      name: 'PDF Parser Agent',
      icon: <FileText className="w-8 h-8" />,
      description: 'Extract and analyze information from PDF documents automatically',
      color: 'blue'
    },
    {
      id: 'rag',
      name: 'RAG Agent',
      icon: <Database className="w-8 h-8" />,
      description: 'Retrieval Augmented Generation for enhanced content processing',
      color: 'purple'
    },
    {
      id: 'qa',
      name: 'Doc QA Agent',
      icon: <Search className="w-8 h-8" />,
      description: 'Intelligent document question-answering system',
      color: 'purple'
    },
    {
      id: 'id',
      name: 'ID Card Agent',
      icon: <BadgeCheck className="w-8 h-8" />,
      description: 'Smart extraction of information from ID cards with bounding box detection',
      color: 'blue'
    },
    {
      id: 'api',
      name: 'API Integration',
      icon: <Code className="w-8 h-8" />,
      description: 'Seamless integration with your existing systems',
      color: 'blue'
    },
    {
      id: 'custom',
      name: 'Custom Agents',
      icon: <Zap className="w-8 h-8" />,
      description: 'Tailored AI agents for your specific needs',
      color: 'orange'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <section className="py-20 px-4 bg-gray-50 dark:bg-gray-800" id="agents">
      <motion.div
        className="max-w-7xl mx-auto"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={containerVariants}
      >
        {/* Section Header */}
        <motion.div variants={cardVariants} className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
              Intelligent Agents
            </span>
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Powerful AI agents designed to streamline your workflow and boost efficiency
          </p>
        </motion.div>

        {/* Agents Grid */}
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          variants={containerVariants}
        >
          {agents.map((agent) => (
            <motion.div
              key={agent.id}
              variants={cardVariants}
              whileHover={{ scale: 1.02 }}
              onHoverStart={() => setHoveredAgent(agent.id)}
              onHoverEnd={() => setHoveredAgent(null)}
              className="relative group"
            >
              <div className="h-full p-8 rounded-2xl bg-white dark:bg-gray-900 shadow-lg hover:shadow-xl transition-all duration-300">
                <div className={`inline-flex items-center justify-center w-16 h-16 rounded-xl mb-6 bg-${agent.color}-500/10 text-${agent.color}-500`}>
                  {agent.icon}
                </div>
                
                <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
                  {agent.name}
                </h3>
                
                <p className="text-gray-600 dark:text-gray-300">
                  {agent.description}
                </p>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: hoveredAgent === agent.id ? 1 : 0 }}
                  className="absolute inset-0 rounded-2xl bg-gradient-to-br from-purple-600/90 to-blue-500/90 p-8 flex flex-col justify-between text-white transform transition-all duration-300 opacity-0 group-hover:opacity-100"
                >
                  <div>
                    <div className="mb-4">{agent.icon}</div>
                    <h3 className="text-2xl font-bold mb-4">{agent.name}</h3>
                    <p className="text-white/90">{agent.description}</p>
                  </div>
                  
                  <button className="mt-6 px-6 py-2 rounded-full bg-white text-purple-600 font-semibold hover:bg-opacity-90 transition-colors duration-300">
                    Learn More
                  </button>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Call to Action */}
        <motion.div
          variants={cardVariants}
          className="mt-16 text-center"
        >
          <button className="px-8 py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg transform hover:scale-105 transition-all duration-300">
            Explore All Agents
          </button>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default AgentsSection;