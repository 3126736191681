// import React, { useEffect, useState } from 'react';
// import { motion } from 'framer-motion';

// const ClientShowcase = () => {
//   const [rotation, setRotation] = useState(0);

//   // Auto-rotate effect
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setRotation(prev => (prev + 1) % 360);
//     }, 50);
//     return () => clearInterval(interval);
//   }, []);

//   const clients = [
//     { name: "solvendo", logo: "/images/solvendo.png" },
//     { name: "auberon", logo: "/images/auberon.png" },
//     { name: "sbl", logo: "/images/sbl.png" },
//     { name: "immunitoAI", logo: "/images/immunitoAI.png" }
//   ];

//   return (
//     <section className="py-20 bg-white dark:bg-gray-800 overflow-hidden">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           viewport={{ once: true }}
//           transition={{ duration: 0.6 }}
//           className="text-center mb-16"
//         >
//           <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//             Trusted by Industry Leaders
//           </h2>
//           <p className="text-xl text-gray-600 dark:text-gray-300">
//             Partnering with innovative companies to transform their AI capabilities
//           </p>
//         </motion.div>

//         <div className="relative h-96">
//           {/* 3D Rotating Circle */}
//           <div className="absolute inset-0 flex items-center justify-center">
//             {clients.map((client, index) => {
//               const angle = (index * 360) / clients.length + rotation;
//               const radius = 200; // Adjust this value to change the circle size
//               const x = Math.cos((angle * Math.PI) / 180) * radius;
//               const z = Math.sin((angle * Math.PI) / 180) * radius;
//               const scale = (z + radius) / (2 * radius); // Scale based on z-position

//               return (
//                 <div
//                   key={client.name}
//                   className="absolute transform -translate-x-1/2 -translate-y-1/2"
//                   style={{
//                     left: `calc(50% + ${x}px)`,
//                     zIndex: Math.floor(scale * 100),
//                     transform: `scale(${0.5 + scale * 0.5})`,
//                     opacity: 0.4 + scale * 0.6,
//                     transition: 'all 0.1s linear',
//                   }}
//                 >
//                   <div className="bg-white dark:bg-gray-700 rounded-lg shadow-xl p-6 w-48 h-24 flex items-center justify-center">
//                     <img
//                       src={client.logo}
//                       alt={client.name}
//                       className="max-w-full max-h-full object-contain"
//                     />
//                   </div>
//                 </div>
//               );
//             })}
//           </div>

//           {/* Center Highlight */}
//           <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-800 via-transparent to-transparent pointer-events-none" />
//         </div>

//         {/* Stats */}
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           viewport={{ once: true }}
//           transition={{ duration: 0.6, delay: 0.3 }}
//           className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-16 text-center"
//         >
//           <div>
//             <div className="text-4xl font-bold text-purple-600 dark:text-purple-400">100+</div>
//             <div className="text-gray-600 dark:text-gray-300 mt-2">Projects Delivered</div>
//           </div>
//           <div>
//             <div className="text-4xl font-bold text-blue-600 dark:text-blue-400">95%</div>
//             <div className="text-gray-600 dark:text-gray-300 mt-2">Client Satisfaction</div>
//           </div>
//           <div>
//             <div className="text-4xl font-bold text-pink-600 dark:text-pink-400">50M+</div>
//             <div className="text-gray-600 dark:text-gray-300 mt-2">API Calls Daily</div>
//           </div>
//           <div>
//             <div className="text-4xl font-bold text-indigo-600 dark:text-indigo-400">15+</div>
//             <div className="text-gray-600 dark:text-gray-300 mt-2">Patents Filed</div>
//           </div>
//         </motion.div>
//       </div>
//     </section>
//   );
// };

// export default ClientShowcase;









// Some issues in safari when looking at the animations


import React, { useEffect, useState, useCallback } from 'react';
import { motion, useAnimation } from 'framer-motion';

const ClientShowcase = () => {
  const [rotation, setRotation] = useState(0);
  const controls = useAnimation();

  // Optimized rotation effect using RequestAnimationFrame
  useEffect(() => {
    let animationFrameId;
    let lastTimestamp = 0;
    const rotationSpeed = 0.05; // Reduced speed for smoother animation

    const animate = (timestamp) => {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const delta = timestamp - lastTimestamp;
      
      setRotation(prev => (prev + rotationSpeed * delta) % 360);
      lastTimestamp = timestamp;
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  const calculatePosition = useCallback((index, totalItems) => {
    const angle = (index * 360) / totalItems + rotation;
    const radius = 200;
    const x = Math.cos((angle * Math.PI) / 180) * radius;
    const z = Math.sin((angle * Math.PI) / 180) * radius;
    const scale = (z + radius) / (2 * radius);
    
    return { x, z, scale };
  }, [rotation]);

  const clients = [
    { name: "solvendo", logo: "/images/solvendo.png" },
    { name: "auberon", logo: "/images/auberon.png" },
    { name: "sbl", logo: "/images/sbl.png" },
    { name: "immunitoAI", logo: "/images/immunitoAI.png" }
  ];

  // Enhanced animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  const statsVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1
      }
    }
  };

  const statItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6
      }
    }
  };

  return (
    <section className="py-20 bg-white dark:bg-gray-800 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
            Trusted by Industry Leaders
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Partnering with innovative companies to transform their AI capabilities
          </p>
        </motion.div>

        <div className="relative h-96">
          <div className="absolute inset-0 flex items-center justify-center">
            {clients.map((client, index) => {
              const { x, z, scale } = calculatePosition(index, clients.length);
              
              return (
                <motion.div
                  key={client.name}
                  className="absolute transform -translate-x-1/2 -translate-y-1/2"
                  style={{
                    left: `calc(50% + ${x}px)`,
                    zIndex: Math.floor(scale * 100),
                    transform: `scale(${0.5 + scale * 0.5})`,
                    opacity: 0.4 + scale * 0.6,
                    willChange: 'transform, opacity',
                    WebkitBackfaceVisibility: 'hidden',
                    WebkitPerspective: 1000,
                    WebkitTransform: 'translateZ(0)',
                  }}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 0.5 + scale * 0.5, opacity: 0.4 + scale * 0.6 }}
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    damping: 20,
                    mass: 0.5
                  }}
                >
                  <div className="bg-white dark:bg-gray-700 rounded-lg shadow-xl p-6 w-48 h-24 flex items-center justify-center transform hover:scale-105 transition-transform duration-300">
                    <img
                      src={client.logo}
                      alt={client.name}
                      className="max-w-full max-h-full object-contain"
                      loading="lazy"
                    />
                  </div>
                </motion.div>
              );
            })}
          </div>

          <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-800 via-transparent to-transparent pointer-events-none" />
        </div>
        {/* <motion.div
variants={statsVariants}
initial="hidden"
whileInView="visible"
viewport={{ once: true }}
className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-16 text-center"
>
{[
  { value: "100+", label: "Projects Delivered", color: "purple" },
  { value: "95%", label: "Client Satisfaction", color: "blue" },
  { value: "50M+", label: "API Calls Daily", color: "pink" },
  { value: "15+", label: "Patents Filed", color: "indigo" }
].map((stat, index) => (
  <motion.div
    key={stat.label}
    variants={statItemVariants}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className={`text-4xl font-bold text-${stat.color}-600 dark:text-${stat.color}-400`}>
      {stat.value}
    </div>
    <div className="text-gray-600 dark:text-gray-300 mt-2">{stat.label}</div>
  </motion.div>
))}
</motion.div> */}
      </div>
    </section>
  );
};

export default ClientShowcase;