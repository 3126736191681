// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { Send, Mail, Phone, MapPin } from 'lucide-react';

// const ContactSection = () => {
//   const [formState, setFormState] = useState({
//     name: '',
//     email: '',
//     message: ''
//   });

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission
//     console.log('Form submitted:', formState);
//   };

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.2
//       }
//     }
//   };

//   const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: { duration: 0.5 }
//     }
//   };

//   return (
//     <section className="relative py-20 bg-gray-50 dark:bg-gray-900 overflow-hidden" id="contact">
//       {/* Background Decorations */}
//       <div className="absolute inset-0 overflow-hidden">
//         <div className="absolute w-96 h-96 -bottom-10 -right-10 bg-purple-200 dark:bg-purple-900/30 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob" />
//         <div className="absolute w-96 h-96 -top-10 -left-10 bg-blue-200 dark:bg-blue-900/30 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70 animate-blob animation-delay-2000" />
//       </div>

//       <motion.div 
//         className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
//         variants={containerVariants}
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true }}
//       >
//         <motion.div 
//           className="text-center mb-16"
//           variants={itemVariants}
//         >
//           <h2 className="text-4xl md:text-5xl font-bold mb-4">
//             <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//               Let's Transform Your Business Together
//             </span>
//           </h2>
//           <p className="text-xl text-gray-600 dark:text-gray-300">
//             Ready to leverage the power of AI? Get in touch with us today.
//           </p>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
//           {/* Contact Form */}
//           <motion.div 
//             className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8"
//             variants={itemVariants}
//           >
//             <form onSubmit={handleSubmit} className="space-y-6">
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   value={formState.name}
//                   onChange={(e) => setFormState({...formState, name: e.target.value})}
//                   className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
//                   placeholder="Your name"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   value={formState.email}
//                   onChange={(e) => setFormState({...formState, email: e.target.value})}
//                   className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
//                   placeholder="your@email.com"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                   What are you looking for?
//                 </label>
//                 <textarea
//                   value={formState.message}
//                   onChange={(e) => setFormState({...formState, message: e.target.value})}
//                   rows={4}
//                   className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
//                   placeholder="Please describe your requirements"
//                 />
//               </div>
//               <button
//                 type="submit"
//                 className="w-full px-6 py-3 rounded-lg bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg transform hover:scale-[1.02] transition-all duration-200 flex items-center justify-center space-x-2"
//               >
//                 <span>Send Message</span>
//                 <Send className="w-5 h-5" />
//               </button>
//             </form>
//           </motion.div>

//           {/* Contact Information */}
//           <motion.div 
//             className="space-y-8"
//             variants={containerVariants}
//           >
//             <motion.div 
//               className="flex items-start space-x-4"
//               variants={itemVariants}
//             >
//               <div className="flex-shrink-0">
//                 <div className="p-4 bg-purple-100 dark:bg-purple-900/30 rounded-lg">
//                   <Mail className="w-6 h-6 text-purple-600 dark:text-purple-400" />
//                 </div>
//               </div>
//               <div>
//                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
//                   Email Us
//                 </h3>
//                 <p className="text-gray-600 dark:text-gray-300">
//                   contact@latentforce.ai
//                 </p>
//               </div>
//             </motion.div>

//             <motion.div 
//               className="flex items-start space-x-4"
//               variants={itemVariants}
//             >
//               <div className="flex-shrink-0">
//                 <div className="p-4 bg-blue-100 dark:bg-blue-900/30 rounded-lg">
//                   <Phone className="w-6 h-6 text-blue-600 dark:text-blue-400" />
//                 </div>
//               </div>
//               <div>
//                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
//                   Call Us
//                 </h3>
//                 <p className="text-gray-600 dark:text-gray-300">
//                 +91 9037022574
//                 </p>
//               </div>
//             </motion.div>

//             <motion.div 
//               className="flex items-start space-x-4"
//               variants={itemVariants}
//             >
//               <div className="flex-shrink-0">
//                 <div className="p-4 bg-pink-100 dark:bg-pink-900/30 rounded-lg">
//                   <MapPin className="w-6 h-6 text-pink-600 dark:text-pink-400" />
//                 </div>
//               </div>
//               <div>
//                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
//                   Visit Us
//                 </h3>
//                 <p className="text-gray-600 dark:text-gray-300">
//                 HD-183, WeWork Vaishnavi Signature, No. 78/9, Outer Ring Road, Bellandur Village, Varthur Hobli, Bengaluru, Karnataka 560103
//                 </p>
//               </div>
//             </motion.div>
//           </motion.div>
//         </div>
//       </motion.div>
//     </section>
//   );
// };

// export default ContactSection;




// Contact section with company name and also sends the data to a backend endpoint

// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { Send, Mail, Phone, MapPin } from 'lucide-react';
// import { toast } from 'react-hot-toast';
// import axios from 'axios';
// import config from './config/config';

// const ContactSection = () => {
//   const [formState, setFormState] = useState({
//     name: '',
//     email: '',
//     company: '',
//     message: ''
//   });

//   const [submitStatus, setSubmitStatus] = useState({
//     isSubmitting: false,
//     status: null,
//     message: ''
//   });

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setSubmitStatus({ isSubmitting: true, status: null, message: '' });
//     try {
//       // const response = await axios.post(`${config.API_BASE_URL}/api/latentforce/contact`,
//       //   formState,
//       //   {
//       //     headers: {
//       //       'Content-Type': 'application/json',
//       //     }
//       //   }
//       // );

//       const url = `${config.API_BASE_URL}/api/latentforce/contact`;
//                   console.log("Request URL:", url);  // Log the full URL to check it
//         const response = await axios.post(url, formState, {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//       setSubmitStatus({
//         isSubmitting: false,
//         status: 'success',
//         message: 'Message sent successfully! We will get back to you soon.'
//       });
//       setFormState({ name: '', email: '', company: '', message: '' });

//       // Trigger a success toast
//       toast.success('Message sent successfully! We will get back to you soon.');

//     } catch (error) {
//       setSubmitStatus({
//         isSubmitting: false,
//         status: 'error',
//         message: 'Failed to send message. Please try again later.'
//       });

//       // Trigger an error toast
//       toast.error('Failed to send message. Please try again later.');
//     }
//   };

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.2
//       }
//     }
//   };

//   const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: { duration: 0.5 }
//     }
//   };

//   return (
//     <section className="relative py-20 bg-gray-50 dark:bg-gray-900 overflow-hidden" id="contact">
//       {/* Background Decorations */}
//       <div className="absolute inset-0 overflow-hidden">
//         <div className="absolute w-96 h-96 -bottom-10 -right-10 bg-purple-200 dark:bg-purple-900/30 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70" />
//         <div className="absolute w-96 h-96 -top-10 -left-10 bg-blue-200 dark:bg-blue-900/30 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70" />
//       </div>

//       <motion.div 
//         className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
//         variants={containerVariants}
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true }}
//       >
//         <motion.div 
//           className="text-center mb-16"
//           variants={itemVariants}
//         >
//           <h2 className="text-4xl md:text-5xl font-bold mb-4">
//             <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//               Let's Transform Your Business Together
//             </span>
//           </h2>
//           <p className="text-xl text-gray-600 dark:text-gray-300">
//             Ready to leverage the power of AI? Get in touch with us today.
//           </p>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
//           {/* Contact Form */}
//           <motion.div 
//             className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8"
//             variants={itemVariants}
//           >
//             <form onSubmit={handleSubmit} className="space-y-6">
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   value={formState.name}
//                   onChange={(e) => setFormState({...formState, name: e.target.value})}
//                   className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
//                   placeholder="Your name"
//                   required
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   value={formState.email}
//                   onChange={(e) => setFormState({...formState, email: e.target.value})}
//                   className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
//                   placeholder="your@email.com"
//                   required
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                   Company (Optional)
//                 </label>
//                 <input
//                   type="text"
//                   value={formState.company}
//                   onChange={(e) => setFormState({...formState, company: e.target.value})}
//                   className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
//                   placeholder="Your company name"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                   What are you looking for?
//                 </label>
//                 <textarea
//                   value={formState.message}
//                   onChange={(e) => setFormState({...formState, message: e.target.value})}
//                   rows={4}
//                   className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
//                   placeholder="Please describe your requirements"
//                   required
//                 />
//               </div>
//               <button
//                 type="submit"
//                 disabled={submitStatus.isSubmitting}
//                 className="w-full px-6 py-3 rounded-lg bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg transform hover:scale-[1.02] transition-all duration-200 flex items-center justify-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
//               >
//                 <span>{submitStatus.isSubmitting ? 'Sending...' : 'Send Message'}</span>
//                 <Send className="w-5 h-5" />
//               </button>
//             </form>
//           </motion.div>

//           {/* Contact Information */}
//           <motion.div 
//             className="space-y-8"
//             variants={containerVariants}
//           >
//             <motion.div 
//               className="flex items-start space-x-4"
//               variants={itemVariants}
//             >
//               <div className="flex-shrink-0">
//                 <div className="p-4 bg-purple-100 dark:bg-purple-900/30 rounded-lg">
//                   <Mail className="w-6 h-6 text-purple-600 dark:text-purple-400" />
//                 </div>
//               </div>
//               <div>
//                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
//                   Email Us
//                 </h3>
//                 <p className="text-gray-600 dark:text-gray-300">
//                   contact@latentforce.ai
//                 </p>
//               </div>
//             </motion.div>

//             <motion.div 
//               className="flex items-start space-x-4"
//               variants={itemVariants}
//             >
//               <div className="flex-shrink-0">
//                 <div className="p-4 bg-blue-100 dark:bg-blue-900/30 rounded-lg">
//                   <Phone className="w-6 h-6 text-blue-600 dark:text-blue-400" />
//                 </div>
//               </div>
//               <div>
//                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
//                   Call Us
//                 </h3>
//                 <p className="text-gray-600 dark:text-gray-300">
//                   +91 9037022574
//                 </p>
//               </div>
//             </motion.div>

//             <motion.div 
//               className="flex items-start space-x-4"
//               variants={itemVariants}
//             >
//               <div className="flex-shrink-0">
//                 <div className="p-4 bg-green-100 dark:bg-green-900/30 rounded-lg">
//                   <MapPin className="w-6 h-6 text-green-600 dark:text-green-400" />
//                 </div>
//               </div>
//               <div>
//                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
//                   Our Office
//                 </h3>
//                 <p className="text-gray-600 dark:text-gray-300">
//                 HD-183, WeWork Vaishnavi Signature, No. 78/9, Outer Ring Road, Bellandur Village, Varthur Hobli, Bengaluru, Karnataka 560103
//                 </p>
//               </div>
//             </motion.div>
//           </motion.div>
//         </div>
//       </motion.div>
//     </section>
//   );
// };

// export default ContactSection;




// Contact section with company name and also sends the data to a backend endpoint
// UPDATED WITH WHICH PRODUCTS USERS WANT TO CHOOSE!


import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send, Mail, Phone, MapPin } from 'lucide-react';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import config from './config/config';

const ContactSection = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
    products: []  // Added products state to manage selected products
  });

  const [submitStatus, setSubmitStatus] = useState({
    isSubmitting: false,
    status: null,
    message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ isSubmitting: true, status: null, message: '' });
    try {
      const url = `${config.API_BASE_URL}/api/latentforce/contact`;
      const response = await axios.post(url, formState, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSubmitStatus({
        isSubmitting: false,
        status: 'success',
        message: 'Message sent successfully! We will get back to you soon.'
      });
      setFormState({ name: '', email: '', company: '', message: '', products: [] });

      // Trigger a success toast
      toast.success('Message sent successfully! We will get back to you soon.');

    } catch (error) {
      setSubmitStatus({
        isSubmitting: false,
        status: 'error',
        message: 'Failed to send message. Please try again later.'
      });

      // Trigger an error toast
      toast.error('Failed to send message. Please try again later.');
    }
  };

  const handleProductChange = (e) => {
    const value = e.target.value;
    setFormState((prevState) => {
      const updatedProducts = prevState.products.includes(value)
        ? prevState.products.filter((product) => product !== value)
        : [...prevState.products, value];
      return { ...prevState, products: updatedProducts };
    });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <section className="relative py-20 bg-gray-50 dark:bg-gray-900 overflow-hidden" id="contact">
      {/* Background Decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-96 h-96 -bottom-10 -right-10 bg-purple-200 dark:bg-purple-900/30 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70" />
        <div className="absolute w-96 h-96 -top-10 -left-10 bg-blue-200 dark:bg-blue-900/30 rounded-full mix-blend-multiply dark:mix-blend-soft-light filter blur-xl opacity-70" />
      </div>

      <motion.div 
        className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div 
          className="text-center mb-16"
          variants={itemVariants}
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-4">
            <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
              Let's Transform Your Business Together
            </span>
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Ready to leverage the power of AI? Get in touch with us today.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <motion.div 
            className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8"
            variants={itemVariants}
          >
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Name
                </label>
                <input
                  type="text"
                  value={formState.name}
                  onChange={(e) => setFormState({...formState, name: e.target.value})}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
                  placeholder="Your name"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  value={formState.email}
                  onChange={(e) => setFormState({...formState, email: e.target.value})}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
                  placeholder="your@email.com"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Company (Optional)
                </label>
                <input
                  type="text"
                  value={formState.company}
                  onChange={(e) => setFormState({...formState, company: e.target.value})}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
                  placeholder="Your company name"
                />
              </div>

              {/* New Question */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Which of our products are you interested in?
                </label>
                <div className="space-y-2">
                  {['Cleo', 'AgentForce', 'ModelForce','Extractors'].map((product) => (
                    <div key={product} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value={product}
                        onChange={handleProductChange}
                        checked={formState.products.includes(product)}
                        className="h-4 w-4 text-purple-500 focus:ring-purple-500"
                      />
                      <label className="text-sm text-gray-700 dark:text-gray-300">{product}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  What are you looking for?
                </label>
                <textarea
                  value={formState.message}
                  onChange={(e) => setFormState({...formState, message: e.target.value})}
                  rows={4}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-transparent focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none transition-all dark:text-white"
                  placeholder="Please describe your requirements"
                  required
                />
              </div>
              <button
                type="submit"
                disabled={submitStatus.isSubmitting}
                className="w-full px-6 py-3 rounded-lg bg-gradient-to-r from-purple-600 to-blue-500 text-white font-semibold hover:shadow-lg transform hover:scale-[1.02] transition-all duration-200 flex items-center justify-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span>{submitStatus.isSubmitting ? 'Sending...' : 'Send Message'}</span>
                <Send className="w-5 h-5" />
              </button>
            </form>
          </motion.div>

          {/* Contact Information */}
          <motion.div 
            className="space-y-8"
            variants={containerVariants}
          >
            <motion.div 
              className="flex items-start space-x-4"
              variants={itemVariants}
            >
              <div className="flex-shrink-0">
                <div className="p-4 bg-purple-100 dark:bg-purple-900/30 rounded-lg">
                  <Mail className="w-6 h-6 text-purple-600 dark:text-purple-300" />
                </div>
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">Email Us</h3>
                <p className="text-gray-600 dark:text-gray-300">contact@latentforce.ai</p>
              </div>
            </motion.div>

            <motion.div 
              className="flex items-start space-x-4"
              variants={itemVariants}
            >
              <div className="flex-shrink-0">
                <div className="p-4 bg-blue-100 dark:bg-blue-900/30 rounded-lg">
                  <Phone className="w-6 h-6 text-blue-600 dark:text-blue-300" />
                </div>
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">Call Us</h3>
                <p className="text-gray-600 dark:text-gray-300">+91 9037022574</p>
                <p className="text-gray-600 dark:text-gray-300">+91 8130391497</p>
              </div>
            </motion.div>

            <motion.div 
              className="flex items-start space-x-4"
              variants={itemVariants}
            >
              <div className="flex-shrink-0">
                <div className="p-4 bg-green-100 dark:bg-green-900/30 rounded-lg">
                  <MapPin className="w-6 h-6 text-green-600 dark:text-green-300" />
                </div>
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">Visit Us</h3>
                <p className="text-gray-600 dark:text-gray-300">HD-183, WeWork Vaishnavi Signature, No. 78/9, Outer Ring Road, Bellandur Village, Varthur Hobli, Bengaluru, Karnataka 560103</p>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default ContactSection;
