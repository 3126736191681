import React from 'react';
import { Linkedin } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const footerSections = {
    company: {
      title: 'LatentForce.ai',
      description: 'AI-powered solutions for modern businesses',
      links: [
        { text: 'About Us', href: null },
        { text: 'Contact', href: null },
        // These will link directly to the files in the public folder
        { text: 'Terms of Service', href: '/terms-of-service.html' },
        { text: 'Privacy Policy', href: '/Privacy Notice.html' }
      ]
    },
    products: {
      title: 'Products',
      links: [
        { text: 'Cleo', href: null },
        { text: 'AgentForce', href: null },
        { text: 'ModelForce', href: null },
        { text: 'Extractors', href: 'https://www.extractors.ai/' }
      ]
    },
    support: {
      title: 'Support',
      links: [
        { text: 'Help Center', href: null },
        { text: 'API Documentation', href: null },
        { text: 'Updates', href: null }
      ]
    },
    social: {
      title: 'Social',
      links: [
        {
          text: 'LinkedIn',
          href: 'https://www.linkedin.com/company/latentforce-ai/posts/?feedView=all',
          icon: <Linkedin className="w-5 h-5" />
        }
      ]
    }
  };

  // Helper function to render link or span based on href presence
  const renderLink = (link) => {
    if (link.href) {
      const isExternal = link.href.startsWith('http');
      return (
        <a
          href={link.href}
          className="text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
          {...(isExternal ? { target: "_blank", rel: "noopener noreferrer" } : {})}
        >
          {link.text}
        </a>
      );
    }
    return (
      <span className="text-gray-400 dark:text-gray-500 cursor-not-allowed">
        {link.text}
      </span>
    );
  };

  return (
    <footer className="bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {/* Company Info */}
          <div className="lg:col-span-2 space-y-4">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white">
              {footerSections.company.title}
            </h3>
            <p className="text-gray-600 dark:text-gray-300">
              {footerSections.company.description}
            </p>
            {/* Social Links */}
            <div className="flex space-x-4 pt-4">
              {footerSections.social.links.map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors"
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>

          {/* Products */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              {footerSections.products.title}
            </h3>
            <ul className="space-y-2">
              {footerSections.products.links.map((link) => (
                <li key={link.text}>
                  {renderLink(link)}
                </li>
              ))}
            </ul>
          </div>

          {/* Company Links */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Company
            </h3>
            <ul className="space-y-2">
              {footerSections.company.links.map((link) => (
                <li key={link.text}>
                  {renderLink(link)}
                </li>
              ))}
            </ul>
          </div>

          {/* Support */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              {footerSections.support.title}
            </h3>
            <ul className="space-y-2">
              {footerSections.support.links.map((link) => (
                <li key={link.text}>
                  {renderLink(link)}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
          <p className="text-center text-gray-500 dark:text-gray-400">
            © {currentYear} LatentForce.ai. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;