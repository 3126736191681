// import React from 'react';
// import { motion } from 'framer-motion';
// import { LinkedinIcon, Twitter } from 'lucide-react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

// const TeamSection = () => {
//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.3
//       }
//     }
//   };

//   const cardVariants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.6,
//         ease: "easeOut"
//       }
//     }
//   };

//   const teamMembers = [
//     {
//       name: "Aravind Jayendran",
//       role: "Co-Founder & CEO",
//       image: "/images/aravind.png",
//       bio: "A pioneer of Generative AI in India, Aravind was among the first at IIT Delhi to experiment with GANs and other cutting-edge models. With nearly a decade of experience, he has built and scaled billion-scale systems at several e-commerce unicorns like Flipkart and Meesho.",
//       linkedin: "https://www.linkedin.com/in/aravind-jayendran-68b21b97/",
//       twitter: "#"
//     },
//     {
//       name: "Prathosh AP, PhD",
//       role: "Chief Research Officer (CRO)",
//       image: "/images/prathosh.png",
//       bio: "Prathosh received his Ph.D from the Indian Institute of Science (IISc), Bangalore in 2015. Currently, he is a faculty member at the department of ECE at IISc, Bangalore. His work has led to 15 US patents, with several commercialized innovations.",
//       linkedin: "https://www.linkedin.com/in/prathosh-ap-phd-50ab9511/",
//       twitter: "#"
//     }
//   ];

//   return (
//     <section className="py-20 bg-gray-50 dark:bg-gray-900" id="team">
//       <motion.div 
//         className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
//         variants={containerVariants}
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true, margin: "-100px" }}
//       >
//         <motion.div variants={cardVariants} className="text-center mb-16">
//           <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//             Founders
//           </h2>
//           <p className="text-xl text-gray-600 dark:text-gray-300">
//             We are ML researchers and engineers from IIT, building cutting-edge AI solutions
//           </p>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
//           {teamMembers.map((member, index) => (
//             <motion.div
//               key={member.name}
//               variants={cardVariants}
//               className="relative group"
//             >
//               <div className="relative rounded-2xl overflow-hidden bg-white dark:bg-gray-800 shadow-xl transition-all duration-300 hover:shadow-2xl">
//                 <div className="absolute inset-0 bg-gradient-to-b from-transparent to-purple-600/20 dark:to-purple-900/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                
//                 <div className="p-8">

// <div className="flex items-center gap-6">
//   <div className="relative w-32 h-32 rounded-full overflow-hidden">
//     <img 
//       src={member.image} 
//       alt={member.name}
//       className="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-110"
//     />
//   </div>
//   <div className="flex-1">
//     <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2 text-left">
//       {member.name}
//     </h3>
//     <p className="text-purple-600 dark:text-purple-400 font-semibold mb-4 text-left">
//       {member.role}
//     </p>
//     <div className="flex gap-4">
//       <a href={member.linkedin} className="text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//         <LinkedinIcon className="w-6 h-6" />
//       </a>
//       <a href={member.twitter} className="text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
//         <Twitter className="w-6 h-6" />
//       </a>
//     </div>
//   </div>
// </div>



//                   <p className="mt-6 text-gray-600 dark:text-gray-300 leading-relaxed">
//                     {member.bio}
//                   </p>
//                 </div>
//               </div>
    
//             </motion.div>
//           ))}
//         </div>
//       </motion.div>
//     </section>
//   );
// };

// export default TeamSection;








// import React from 'react';
// import { motion } from 'framer-motion';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

// const TeamSection = () => {
//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.3
//       }
//     }
//   };

//   const cardVariants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.6,
//         ease: "easeOut"
//       }
//     }
//   };

//   const teamMembers = [
//     {
//       name: "Aravind Jayendran",
//       role: "Co-Founder & CEO",
//       image: "/images/aravind.png",
//       bio: "A pioneer of Generative AI in India, Aravind was among the first at IIT Delhi to experiment with GANs and other cutting-edge models. With nearly a decade of experience, he has built and scaled billion-scale systems at several e-commerce unicorns like Flipkart and Meesho.",
//       linkedin: "https://www.linkedin.com/in/aravind-jayendran-68b21b97/",
//       twitter: "#"
//     },
//     {
//       name: "Prathosh AP, PhD",
//       role: "Chief Research Officer (CRO)",
//       image: "/images/prathosh.png",
//       bio: "Prathosh received his Ph.D from the Indian Institute of Science (IISc), Bangalore in 2015. Currently, he is a faculty member at the department of ECE at IISc, Bangalore. His work has led to 15 US patents, with several commercialized innovations.",
//       linkedin: "https://www.linkedin.com/in/prathosh-ap-phd-50ab9511/",
//       twitter: "#"
//     }
//   ];

//   return (
//     <section className="py-20 bg-gray-50 dark:bg-gray-900" id="team">
//       <motion.div
//         className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
//         variants={containerVariants}
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true, margin: "-100px" }}
//       >
//         <motion.div variants={cardVariants} className="text-center mb-16">
//           <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
//             Founders
//           </h2>
//           <p className="text-xl text-gray-600 dark:text-gray-300">
//             We are ML researchers and engineers from IIT, building cutting-edge AI solutions
//           </p>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
//           {teamMembers.map((member) => (
//             <motion.div
//               key={member.name}
//               variants={cardVariants}
//               className="relative group"
//             >
//               <div className="relative rounded-2xl overflow-hidden bg-white dark:bg-gray-800 shadow-xl transition-all duration-300 hover:shadow-2xl">
//                 <div className="absolute inset-0 bg-gradient-to-b from-transparent to-purple-600/20 dark:to-purple-900/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

//                 <div className="p-8">
//                   <div className="flex items-center gap-6">
//                     <div className="relative w-32 h-32 rounded-full overflow-hidden">
//                       <img
//                         src={member.image}
//                         alt={member.name}
//                         className="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-110"
//                       />
//                     </div>
//                     <div className="flex-1">
//                       <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2 text-left">
//                         {member.name}
//                       </h3>
//                       <p className="text-purple-600 dark:text-purple-400 font-semibold mb-4 text-left">
//                         {member.role}
//                       </p>
//                       <div className="flex gap-4 z-10">
//                         <a
//                           href={member.linkedin}
//                           target="_blank"
//                           rel="noopener noreferrer"
//                           className="text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-colors cursor-pointer"
//                         >
//                           <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6" />
//                         </a>
//                         <a
//                           href={member.twitter}
//                           target="_blank"
//                           rel="noopener noreferrer"
//                           className="text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-colors cursor-pointer"
//                         >
//                           <FontAwesomeIcon icon={faTwitter} className="w-6 h-6" />
//                         </a>
//                       </div>
//                     </div>
//                   </div>

//                   <p className="mt-6 text-gray-600 dark:text-gray-300 leading-relaxed">
//                     {member.bio}
//                   </p>
//                 </div>
//               </div>
//             </motion.div>
//           ))}
//         </div>
//       </motion.div>
//     </section>
//   );
// };

// export default TeamSection;














// import React from 'react';

// const TeamSection = () => {
//   const teamMembers = [
//     {
//       name: "Aravind Jayendran",
//       role: "Co-Founder & CEO",
//       image: "/images/aravind.png",
//       bio: "A pioneer of Generative AI in India, Aravind was among the first at IIT Delhi to experiment with GANs and other cutting-edge models. With nearly a decade of experience, he has built and scaled billion-scale systems at several e-commerce unicorns like Flipkart and Meesho.His extensive expertise spans deep learning, recommendation systems, and sequence modeling. Aravind has authored multiple publications in leading ML conferences and holds several pending patents, driving innovation and excellence at LatentForce.ai.",
//       linkedin: "https://www.linkedin.com/in/aravind-jayendran-68b21b97/"
//     },
//     {
//       name: "Prathosh AP, PhD",
//       role: "Chief Research Officer (CRO)",
//       image: "/images/prathosh.png",
//       bio: "Prathosh received his Ph. D from the Indian Institute of Science (IISc), Bangalore in 2015, in the area of temporal data analysis. Subsequently, he has worked in corporate research labs including Xerox Research India, Philips research, and a start-up in CA, USA. His work in the industry, focussing on healthcare analytics, led to the generation of several IP, comprising 15 (US) patents of which several are commercialized. Currently, he is a faculty member at the department of ECE at IISc, Bangalore. His current research includes deep-representational learning, cross-domain generalization, signal processing, and their applications in computer vision and speech analytics. He has co-founded a start-up Cogniable. ",
//       linkedin: "https://www.linkedin.com/in/prathosh-ap-phd-50ab9511/"
//     }
//   ];

//   return (
//     <section className="py-20 bg-gray-50" id="team">
//       <div className="max-w-7xl mx-auto px-4">
//         <h2 className="text-4xl font-bold mb-4 text-center text-purple-600">
//         Innovative Minds Behind 
//         </h2>
//         <p className="text-xl text-gray-600 text-center mb-16">
//         We are a team of Machine Learning (ML) researchers from the renowed Indian Institute of Technology (IIT), with experience working with multiple Unicorns and deep-tech startups in India. Our expertise lies in building 0-1 ML products that serve millions of users. We are dedicated to leveraging our knowledge and skills to transform your business with cutting-edge AI solutions.
//         </p>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
//           {teamMembers.map((member) => (
//             <div key={member.name} className="bg-white rounded-2xl shadow-lg p-8">
//               <div className="flex items-center gap-6">
//                 <div className="w-32 h-32 rounded-full overflow-hidden">
//                   <img
//                     src={member.image}
//                     alt={member.name}
//                     className="w-full h-full object-cover"
//                   />
//                 </div>
//                 <div>
//                   <h3 className="text-2xl font-bold text-gray-900 mb-2">
//                     {member.name}
//                   </h3>
//                   <p className="text-purple-600 font-semibold mb-4">
//                     {member.role}
//                   </p>
//                   <a
//                     href={member.linkedin}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="inline-block p-2 text-gray-600 hover:text-purple-600 hover:bg-purple-50 rounded-full transition-colors cursor-pointer"
//                   >
//                     {/* Simple LinkedIn SVG icon */}
//                     <svg 
//                       className="w-6 h-6"
//                       fill="currentColor"
//                       viewBox="0 0 24 24"
//                       aria-hidden="true"
//                     >
//                       <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
//                     </svg>
//                   </a>
//                 </div>
//               </div>
//               <p className="mt-6 text-gray-600 leading-relaxed">
//                 {member.bio}
//               </p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TeamSection;






import React from 'react';
import { motion } from 'framer-motion';

const TeamSection = () => {
  const teamMembers = [
    {
      name: "Aravind Jayendran",
      role: "Co-Founder & CEO",
      image: "/images/aravind.png",
      bio: "A pioneer of Generative AI in India, Aravind was among the first at IIT Delhi to experiment with GANs and other cutting-edge models. With nearly a decade of experience, he has built and scaled billion-scale systems at several e-commerce unicorns like Flipkart and Meesho.His extensive expertise spans deep learning, recommendation systems, and sequence modeling. Aravind has authored multiple publications in leading ML conferences and holds several pending patents, driving innovation and excellence at LatentForce.ai.",
      linkedin: "https://www.linkedin.com/in/aravind-jayendran-68b21b97/"
    },
    {
      name: "Prathosh AP, PhD",
      role: "Chief Research Officer (CRO)",
      image: "/images/prathosh.png",
      bio: "Prathosh received his Ph. D from the Indian Institute of Science (IISc), Bangalore in 2015, in the area of temporal data analysis. Subsequently, he has worked in corporate research labs including Xerox Research India, Philips research, and a start-up in CA, USA. His work in the industry, focussing on healthcare analytics, led to the generation of several IP, comprising 15 (US) patents of which several are commercialized. Currently, he is a faculty member at the department of ECE at IISc, Bangalore. His current research includes deep-representational learning, cross-domain generalization, signal processing, and their applications in computer vision and speech analytics. He has co-founded a start-up Cogniable. ",
      linkedin: "https://www.linkedin.com/in/prathosh-ap-phd-50ab9511/"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6
      }
    },
    hover: {
      y: -10,
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <section className="py-20 bg-gray-50 dark:bg-gray-900 transition-colors duration-200" id="team">
      <div className="max-w-7xl mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold mb-4 text-center text-purple-600 dark:text-purple-400"
        >
          Innovative Minds Behind
        </motion.h2>
        
        <motion.p 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-xl text-gray-600 dark:text-gray-300 text-center mb-16"
        >
          We are a team of Machine Learning (ML) researchers from the renowed Indian Institute of Technology (IIT), with experience working with multiple Unicorns and deep-tech startups in India. Our expertise lies in building 0-1 ML products that serve millions of users. We are dedicated to leveraging our knowledge and skills to transform your business with cutting-edge AI solutions.
        </motion.p>

        <motion.div 
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 gap-12"
        >
          {teamMembers.map((member) => (
            <motion.div
              key={member.name}
              variants={cardVariants}
              whileHover="hover"
              className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg dark:shadow-gray-700/30 p-8 transition-colors duration-200"
            >
              <div className="flex items-center gap-6">
                <motion.div 
                  className="w-32 h-32 rounded-full overflow-hidden"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-full object-cover"
                  />
                </motion.div>
                <div>
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                    {member.name}
                  </h3>
                  <p className="text-purple-600 dark:text-purple-400 font-semibold mb-4">
                    {member.role}
                  </p>
                  <motion.a
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block p-2 text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 hover:bg-purple-50 dark:hover:bg-purple-900/30 rounded-full transition-colors cursor-pointer"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <svg 
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                    </svg>
                  </motion.a>
                </div>
              </div>
              <p className="mt-6 text-gray-600 dark:text-gray-300 leading-relaxed">
                {member.bio}
              </p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default TeamSection;